export default {

    isUndefined(value) {
        return value === undefined;
    },
    isNull(value) {
        return value === null;
    },
    isObject(value) {
        return value === Object(value);
    },
    isArray(value) {
        return Array.isArray(value);
    },
    isDate(value) {
        return value instanceof Date;
    },
    isBlob(value) {
        return (
            value &&
            typeof value.size === 'number' &&
            typeof value.type === 'string' &&
            typeof value.slice === 'function'
        );
    },
    isFile(value) {
        return (
            this.isBlob(value) &&
            (typeof value.lastModifiedDate === 'object' ||
                typeof value.lastModified === 'number') &&
            typeof value.name === 'string'
        );
    },
    isFormData(value) {
        return value instanceof FormData;
    },
    formData(obj, cfg, fd, pre) {
        var _self = this;
        if (_self.isFormData(cfg)) {
            pre = fd;
            fd = cfg;
            cfg = null;
        }

        cfg = cfg || {};
        cfg.indices = _self.isUndefined(cfg.indices) ? false : cfg.indices;
        cfg.nulls = _self.isUndefined(cfg.nulls) ? true : cfg.nulls;
        fd = fd || new FormData();

        if (_self.isUndefined(obj)) {
            return fd;
        } else if (_self.isNull(obj)) {
            if (cfg.nulls) {
                fd.append(pre, '');
            }
        } else if (_self.isArray(obj)) {
            if (!obj.length) {
                var key = pre + '[]';

                //fd.append(key, '');
            } else {
                obj.forEach(function(value, index) {
                    var key = pre + '[' + (cfg.indices ? index : '') + ']';

                    _self.formData(value, cfg, fd, key);
                });
            }
        } else if (_self.isDate(obj)) {
            fd.append(pre, obj.toISOString());
        } else if (_self.isObject(obj) && !_self.isFile(obj) && !_self.isBlob(obj)) {
            Object.keys(obj).forEach(function(prop) {
                var value = obj[prop];

                if (_self.isArray(value)) {
                    while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
                        prop = prop.substring(0, prop.length - 2);
                    }
                }

                var key = pre ? pre + '[' + prop + ']' : prop;

                _self.formData(value, cfg, fd, key);
            });
        } else if (_.isBoolean(obj)) {
            fd.append(pre, (obj)? "1":"0");
        } else {
            fd.append(pre, obj);
        }

        return fd;
    }
}